import React from "react";
import { graphql, Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import EditOnGithub from "../components/editOnGithub";

import styles from "../styles/post.module.scss";

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data; // data.markdownRemark holds our post data
  const { frontmatter, html, fileAbsolutePath } = markdownRemark;
  const { title, description, date, featured_image, lastmod } = frontmatter;

  return (
    <Layout>
      <SEO title={title} description={description} linkImage={featured_image} />
      <div className={styles.postContainer}>
        <div
          className={styles.featuredImage}
          style={{ backgroundImage: `url('/images/${featured_image}')` }}
        />
        <article className={styles.post}>
          <h1>{title}</h1>
          <div className={styles.dates}>
            <div>created: {date}</div>
            <div>updated: {lastmod}</div>
          </div>
          <div
            className="post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
          <footer className={styles.contributeFooter}>
            <p>
              Contribute to this page:{" "}
              <EditOnGithub filePath={fileAbsolutePath} />,{" "}
              <Link to="/contributing">How to contribute</Link>
            </p>
          </footer>
        </article>
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(fields: { path: { eq: $path } }) {
      html
      fileAbsolutePath
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        lastmod(formatString: "MMMM DD, YYYY")
        featured_image
        title
        country
        type
      }
      fields {
        path
      }
    }
  }
`;
