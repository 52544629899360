class ContributionHelper {
  /**
   *
   * @param {string} absoluteFilePath
   */
  static githubLinkFromAbsPath(absoluteFilePath) {
    const baseRepoPath = `https://github.com/GegenWindPhoto/law.photography-content/blob/master`;

    const separator = "content";
    const separatorPosition = absoluteFilePath.indexOf(separator);

    const relativePath = absoluteFilePath.substring(
      separatorPosition + separator.length,
      absoluteFilePath.length
    );
    return `${baseRepoPath}${relativePath}`;
  }
}

export default ContributionHelper;
