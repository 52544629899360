import React from "react";
import PropTypes from "prop-types";

import ContributionHelper from "../lib/contributionHelper";

class EditOnGithub extends React.Component {
  render() {
    return (
      <a href={ContributionHelper.githubLinkFromAbsPath(this.props.filePath)}>
        Edit this page
      </a>
    );
  }
}

EditOnGithub.propTypes = {
  filePath: PropTypes.string,
};

export default EditOnGithub;
